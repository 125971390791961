import { Box, Fab, Typography, useMediaQuery, useTheme } from '@mui/material';

import TAGLink from '@/components/TAGLink';
import TAGSvgIcon, { IconName } from '@/components/TAGSvgIcon';

import { socialMediaLinks } from './SocialMediaLinks';
import { mobileShareableLinkStyles } from './BlogDetailsPage.styles';

//NOTE: This still needs to be turned into a contentful array
const socialMediaNames = ['Facebook', 'X', 'Threads', 'Reddit', 'LinkedIn', 'Email'];
const SocialMediaShareLinks = () => {
	const theme = useTheme();
	const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	const shareableLinkPath = (linkUrl: string) => {
		if (globalThis?.location?.href) {
			return linkUrl + globalThis?.location?.href;
		}
		return linkUrl;
	};

	return (
		<>
			{socialMediaLinks.map((link) => {
				if (socialMediaNames.includes(link.name)) {
					return (
						<TAGLink
							href={shareableLinkPath(link.url)}
							openInNewTab
							noLinkStyle
							key={link.name}
							dataTestId={
								isMobileScreen
									? `shareable_mobile_link_${link.name}`
									: `shareable_desktop_link_${link.name}`
							}
						>
							<Box sx={isMobileScreen ? mobileShareableLinkStyles : null}>
								<Fab
									size="S"
									variant="alternative"
									data-test-id={
										isMobileScreen
											? `mobile_social_media_button_${link.icon}`
											: `desktop_social_media_button_${link.icon}`
									}
								>
									<TAGSvgIcon icon={link.icon as IconName} size={24} viewBox="0 0 24 24" />
								</Fab>
								{isMobileScreen && (
									<Typography
										data-test-id={`mobile_shareable_link_text_${link.name}`}
										variant={'assistiveText'}
										color="text.primary"
									>
										{link.name}
									</Typography>
								)}
							</Box>
						</TAGLink>
					);
				}
				return null;
			})}
		</>
	);
};

export default SocialMediaShareLinks;
