export const socialMediaLinks = [
	{
		name: 'Facebook',
		url: 'https://www.facebook.com/sharer/sharer.php?u=',
		icon: 'Facebook',
	},
	{
		name: 'X',
		url: 'https://twitter.com/intent/tweet?url=',
		icon: 'XCompany',
	},
	{
		name: 'LinkedIn',
		url: 'https://www.linkedin.com/sharing/share-offsite/?url=',
		icon: 'LinkedIn',
	},
	{
		name: 'Email',
		url: 'mailto:?subject=Check out this blog post&body=I thought you might find this blog post interesting: ',
		icon: 'Email',
	},
	{
		name: 'Threads',
		url: 'https://www.threads.net/intent/post?text=',
		icon: 'Threads',
	},
	{
		name: 'Reddit',
		url: 'https://www.reddit.com/r/subreddit/submit?url=',
		icon: 'Reddit',
	},
];
