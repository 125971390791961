import { Box, useTheme } from '@mui/material';

import { pullerTab } from './PullerTabStyles';

interface IPullerTabProps {
	position?: string;
}

export default function PullerTab({ position }: IPullerTabProps) {
	const theme = useTheme();
	return <Box sx={pullerTab(theme, position)} />;
}
