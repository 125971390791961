import { Theme } from '@mui/material';

export const getImageWrapperStyles = (isMobileScreen: boolean) => ({
	width: isMobileScreen ? 'calc(100% + 2.5rem)' : '50%',
	height: isMobileScreen ? 'auto' : '100vh',
	...(isMobileScreen && { marginTop: '1.5rem' }),
});

export const getContainerStyles = (isMobileScreen: boolean) => ({
	display: 'flex',
	flexDirection: isMobileScreen ? 'column' : 'row',
});

export const getContentContainerStyles = (isMobileScreen: boolean) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: isMobileScreen ? 'center' : 'flex-start',
	alignItems: isMobileScreen ? 'center' : 'flex-start',
	gap: '1rem',
	px: { xs: 3, md: '2%', lg: '5%' },
	py: isMobileScreen ? '2.5rem' : '3.75rem',
	...(!isMobileScreen && { maxHeight: '100vh', overflowY: 'scroll', width: '50%' }),
});

export const shareContentStyles = (isMobileScreen: boolean, theme: Theme) => ({
	display: 'flex',
	paddingTop: isMobileScreen ? '1.25rem' : '2.5rem',
	flexDirection: 'column',
	alignItems: 'flex-start',
	gap: '1rem',
	alignSelf: 'stretch',
	borderTop: '0.063rem solid',
	borderColor: theme.palette.neutrals?.primary,
});

export const socialMediaLinkStyles = {
	display: 'flex',
	alignItems: 'flex-start',
	gap: '1.25rem',
	flexWrap: 'wrap',
	zIndex: 1,
};

export const drawerStyles = {
	'.MuiDrawer-paperAnchorBottom': {
		display: 'flex',
		height: '28.938rem',
		padding: '0 1.25rem',
		flexDirection: 'column',
		alignItems: 'center',
		flexShrink: 0,
		borderRadius: '1rem 1rem 0 0',
	},
};

export const pullerBoxStyles = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '3rem',
	height: '3rem',
	flexShrink: 0,
};

export const mobileIconContainer = {
	display: 'flex',
	paddingTop: '1.75rem',
	gap: '1.25rem',
	flexWrap: 'wrap',
};

export const mobileShareableLinkStyles = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	gap: '.25rem',
	width: '4.297rem',
	flex: '1 0 0',
};
export const mobileTitleStyles = { textAlign: 'center' };
export const shareIconStyles = { fontSize: 20 };

export const blogAuthorCtrStyles = {
	marginY: '2.5rem',
	paddingY: { xs: '1rem', md: '0' },
	alignSelf: 'flex-start',
};

export const blogAuthorNameStyles = {
	display: 'flex',
	flexDirection: 'column',
	gap: '.5rem',
};

export const blogAuthorSummaryStyles = {
	marginY: '1rem',
};

export const blogAuthorImageNameCtrStyles = {
	display: 'flex',
	flexDirection: { xs: 'column', md: 'row' },
	gap: '1rem',
};

export const blogAuthorImageCtrStyles = {
	height: '5rem',
	width: '5rem',
	borderRadius: '50%',
	backgroundColor: 'backgrounds.neutral',
};

export const blogAuthorImageStyles = {
	height: '5rem',
	width: '5rem',
	borderRadius: '50%',
};
