import { Theme } from '@mui/material';

export const pullerTab = (theme: Theme, position?: string) => {
	return {
		width: '2.75rem',
		height: '0.25rem',
		backgroundColor: theme.palette.neutrals?.disabled,
		borderRadius: '0.188rem',
		position: position ? position : '',
		top: '0.5rem',
		left: 'calc(50% - .938rem)',
	};
};
