import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import FocalPointImage from '@/components/FocalPointImage';
import { BlogAuthorAuthorBioSummary, ContentTypeRichTextContent, Maybe } from '@/types/generated';
import ContentTypeRichText from '@/components/ContentTypeRichText';

import {
	blogAuthorCtrStyles,
	blogAuthorImageCtrStyles,
	blogAuthorImageNameCtrStyles,
	blogAuthorImageStyles,
	blogAuthorNameStyles,
	blogAuthorSummaryStyles,
} from './BlogDetailsPage.styles';
import { BlogImage } from './BlogDetailsPage';

export interface IBlogAuthorProps {
	/**
	 * Required field for the BlogDetailsPage Author
	 *
	 * @type {string}
	 * @memberof IBlogAuthorProps
	 */
	name: string;
	/**
	 * Optional field for the BlogDetailsPage Author Title
	 *
	 * @type {string}
	 * @memberof IBlogAuthorProps
	 */
	title?: string | null;
	/**
	 * Optional field for the BlogDetailsPage Author Image
	 *
	 * @type {Array<IBlogDetailPageImage>;}
	 * @memberof IBlogDetailsPage
	 */
	image?: BlogImage | null;
	/**
	 * Optional richtext field for the BlogDetailsPage Author Bio Summary
	 *
	 * @type {Maybe<BlogAuthorAuthorBioSummary>}
	 * @memberof IBlogAuthorProps
	 */
	authorBioSummary?: Maybe<BlogAuthorAuthorBioSummary>;
}
export default function BlogAuthorDetails({ name, title, image, authorBioSummary }: IBlogAuthorProps) {
	const theme = useTheme();
	const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	const responsiveTypeVariant = isMobileScreen ? 'bodyMediumBook' : 'bodyLargeBook';

	return (
		<Box data-test-id="blog_detail_page_author_container" sx={blogAuthorCtrStyles} component="section">
			<Box sx={blogAuthorImageNameCtrStyles}>
				<Box sx={blogAuthorImageCtrStyles} data-test-id="blog_detail_page_author_focal_point_image_container">
					{image && (
						<FocalPointImage
							brandFolderImage={image.image}
							focalPoint={image.focalPoint}
							dataTestId="blog_detail_page_author_focal_point_image"
							imageSx={blogAuthorImageStyles}
						/>
					)}
				</Box>
				<Box sx={blogAuthorNameStyles}>
					<Typography
						data-test-id="blog_detail_page_author_name"
						variant="header3"
						color="text.primary"
						component="span"
					>
						{name}
					</Typography>
					{title && title !== '' && (
						<Typography
							data-test-id="blog_detail_page_author_title"
							variant="overline"
							color="text.primary"
						>
							{title}
						</Typography>
					)}
				</Box>
			</Box>
			{authorBioSummary?.json && (
				<ContentTypeRichText
					content={authorBioSummary as ContentTypeRichTextContent}
					dataTestId="blog_detail_page_author_summary_richtext"
					contentMaxWidth="100%"
					disableGutters={true}
					disablePaddingOn={['Top', 'Bottom']}
					containerSx={blogAuthorSummaryStyles}
					docProps={{ variant: responsiveTypeVariant, color: 'text.secondary' }}
				/>
			)}
		</Box>
	);
}
