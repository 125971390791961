import { useState } from 'react';

import { Box, SwipeableDrawer, Typography, useMediaQuery, useTheme } from '@mui/material';
import { format } from 'date-fns';
import IosShareIcon from '@mui/icons-material/IosShare';

import {
	BlogCategory,
	BlogDetailsPageSummary,
	BlogDetailsPageText,
	BlogSubCategory,
	ContentTypeRichTextContent,
	ImageAsset as TImageAsset,
	Maybe,
} from '@/types/generated';
import ContentTypeRichText from '@/components/ContentTypeRichText';
import FocalPointImage, { BrandFolderImage, FocalPointData } from '@/components/FocalPointImage';
import ImageAsset from '@/components/ImageAsset';
import { IImage } from '@/components/ImageAsset/ImageAsset';

import PullerTab from '../PullerTab/PullerTab';
import TAGButton from '../TAGButton';

import {
	getContentContainerStyles,
	getImageWrapperStyles,
	getContainerStyles,
	shareContentStyles,
	socialMediaLinkStyles,
	drawerStyles,
	pullerBoxStyles,
	mobileIconContainer,
	mobileTitleStyles,
	shareIconStyles,
} from './BlogDetailsPage.styles';
import BlogAuthorDetails, { IBlogAuthorProps } from './BlogAuthorDetails';
import SocialMediaShareLinks from './SocialMediaShareLinks';
export interface BlogImage {
	image: BrandFolderImage;
	focalPoint: FocalPointData;
}
export interface IBlogDetailsPage {
	/**
	 * Required field for the BlogDetailsPage title
	 *
	 * @type {string}
	 * @memberof IBlogDetailsPage
	 */
	title: string;
	/**
	 * Required field for the BlogDetailsPage path
	 *
	 * @type {string}
	 * @memberof IBlogDetailsPage
	 */
	path: string;
	/**
	 * Required field to identify the date of the blog
	 *
	 * @type {string}
	 * @memberof IBlogDetailsPage
	 */
	date: string;
	/**
	 * Required field to identify the category of the blog.
	 *
	 * @type {Maybe<BlogCategory>}
	 * @memberof IBlogDetailsPage
	 */
	category: Maybe<BlogCategory>;
	/**
	 * Required field to identify the category of the blog.
	 *
	 * @type {Maybe<BlogSubCategory>}
	 * @memberof IBlogDetailsPage
	 */
	subCategory: Maybe<BlogSubCategory>;
	/**
	 * Required field to identify the time to read for the blog
	 *
	 * @type {number}
	 * @memberof IBlogDetailsPage
	 */
	timeToRead: number;
	/**
	 * Required field to identify the blog summary
	 *
	 * @type {Maybe<BlogDetailsPageSummary>;}
	 * @memberof IBlogDetailsPage
	 */
	summary: Maybe<BlogDetailsPageSummary>;
	/**
	 * Required field to identify the blog text
	 *
	 * @type {Maybe<BlogDetailsPageText>;}
	 * @memberof IBlogDetailsPage
	 */
	text: Maybe<BlogDetailsPageText>;
	/**
	 * Required field to identify the blog image
	 *
	 * @type {Array<IBlogDetailPageImage>;}
	 * @memberof IBlogDetailsPage
	 */
	blogImage: BlogImage;
	/**
	 * Optional field to identify the blog author
	 *
	 * @type {IBlogAuthorProps;}
	 * @memberof IBlogDetailsPage
	 */
	author?: IBlogAuthorProps;
	/**
	 * Brandfolder Image with focal point.
	 *
	 * @type {Maybe<ImageAsset>}
	 * @memberof IBlogDetailsPage
	 */
	imageAsset?: Maybe<TImageAsset>;
}

export default function BlogDetailsPage({
	title,
	date,
	summary,
	text,
	blogImage,
	author,
	imageAsset,
}: IBlogDetailsPage) {
	const [openDrawer, setOpenDrawer] = useState(false);
	const theme = useTheme();
	const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	const formattedDate = format(new Date(date), 'MM.dd.yyyy');

	const responsiveTypeVariant = isMobileScreen ? 'bodyMediumBook' : 'bodyLargeBook';
	return (
		<Box data-test-id="blog_detail_page_container" sx={getContainerStyles(isMobileScreen)}>
			{!isMobileScreen && (
				<Box sx={getImageWrapperStyles(isMobileScreen)}>
					{imageAsset ? (
						<ImageAsset
							desktopImage={imageAsset?.desktopImage as IImage}
							dataTestId="blog_detail_page_img_asset_focal_point_image_desktop"
						/>
					) : (
						<FocalPointImage
							brandFolderImage={blogImage?.image}
							focalPoint={blogImage?.focalPoint}
							dataTestId="blog_detail_page_focal_point_image_desktop"
						/>
					)}
				</Box>
			)}
			<Box
				data-test-id="blog_detail_page_content_container"
				sx={getContentContainerStyles(isMobileScreen)}
				component="article"
			>
				<Typography
					data-test-id="blog_detail_page_content_date"
					variant={responsiveTypeVariant}
					color="text.secondary"
					component="span"
				>
					{formattedDate}
				</Typography>

				<Typography
					variant={isMobileScreen ? 'heroMedium' : 'heroLarge'}
					color="text.primary"
					component="h1"
					textAlign={isMobileScreen ? 'center' : 'left'}
				>
					{title}
				</Typography>

				{summary?.json && (
					<ContentTypeRichText
						content={summary as ContentTypeRichTextContent}
						dataTestId="blog_detail_page_content_summary_richtext"
						contentMaxWidth="100%"
						disableGutters={true}
						disablePaddingOn={['Top', 'Bottom']}
						backgroundColor="transparent"
						centerAlign={isMobileScreen ? true : false}
						docProps={{ variant: responsiveTypeVariant, color: 'text.secondary' }}
					/>
				)}
				{isMobileScreen && (
					<Box sx={getImageWrapperStyles(isMobileScreen)}>
						{imageAsset ? (
							<ImageAsset
								desktopImage={imageAsset?.desktopImage as IImage}
								mobileImage={imageAsset?.mobileImage as IImage}
								dataTestId="blog_detail_page_img_asset_focal_point_image_mobile"
							/>
						) : (
							<FocalPointImage
								brandFolderImage={blogImage?.image}
								focalPoint={blogImage?.focalPoint}
								dataTestId="blog_detail_page_focal_point_image_mobile"
							/>
						)}
					</Box>
				)}
				<ContentTypeRichText
					content={text as ContentTypeRichTextContent}
					dataTestId="blog_detail_page_content_text_richtext"
					contentMaxWidth="100%"
					disableGutters={true}
					disablePaddingOn={['Top', 'Bottom']}
					containerSx={{ marginTop: '1.5rem' }}
					backgroundColor="transparent"
				/>
				{author && <BlogAuthorDetails {...author} />}
				<Box sx={shareContentStyles(isMobileScreen, theme)}>
					<Typography
						data-test-id="share_article_title_text"
						variant={'header3'}
						color="text.primary"
						component="span"
					>
						{isMobileScreen ? 'Share this article' : 'Share'}
					</Typography>
					<Box sx={socialMediaLinkStyles}>
						{isMobileScreen ? (
							<TAGButton
								size="S"
								variant="secondaryDefault"
								endIcon={<IosShareIcon sx={shareIconStyles} />}
								onClick={() => setOpenDrawer(true)}
								dataTestId="mobile_shareable_link_button"
							>
								Share
							</TAGButton>
						) : (
							<SocialMediaShareLinks />
						)}
					</Box>
				</Box>
			</Box>
			{isMobileScreen && (
				<SwipeableDrawer
					anchor="bottom"
					onClose={() => setOpenDrawer(false)}
					onOpen={() => setOpenDrawer(true)}
					open={openDrawer}
					sx={drawerStyles}
					data-test-id={'shareable_mobile_link_drawer'}
				>
					<Box sx={pullerBoxStyles}>
						<PullerTab />
					</Box>
					<Typography sx={mobileTitleStyles} variant={'header2'} color="text.primary" component="span">
						Share this article
					</Typography>
					<Box sx={mobileIconContainer}>
						<SocialMediaShareLinks />
					</Box>
				</SwipeableDrawer>
			)}
		</Box>
	);
}
